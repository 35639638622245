import React, { useState, useEffect } from "react";
import { Navbar } from "../Navbar";
import { Footer } from "./Footer";
import BlogItem from "./BlogItem";
import { useLocation, Link } from 'react-router-dom';
import axiosConfig from "../../Service/axiosConfig";
import { Helmet } from "react-helmet";

const BlogDetail = ({ item }) => {
    const location = useLocation();
    const [relatedBlogs, setRelatedBlogs] = useState([]);
    const GetRelatedList = async () => {
        try {
            const response = await axiosConfig.get(`/blogapp/blog/?page_size=3&category=${''}`)
            setRelatedBlogs(response.data.results)
        } catch (error) {
            console.log("Error getting related blog data :", error)
        }
    }
    const CallMetaData = async() => {
        try {
            await axiosConfig.get(`/blogapp/blogs/${item?.id}/`)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        CallMetaData();
        GetRelatedList();
    }, [])
    const prev_sub_page = {
        link: `/blog/${item?.category?.slug}`,
        text: item?.category?.slug,
        state: { category: item?.category?.id } // Example for dynamic generation
    };
    return (
        <div>
            <Navbar />
            {/* <section className="about-breadcrumb">
                <BreadCrumb active_page={item?.slug} prev_sub_page={prev_sub_page} />
            </section> */}
            <Helmet>
                {/* Page Title */}
                <title>{item?.title}</title>

                {/* Meta Tags for SEO */}
                <meta property="og:title" content={item?.title} />
                <meta property="og:description" content={item?.description} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={item?.image?.image} />
            </Helmet>

            <div className="container">
                <div className="blog-detail">
                    <h2>{item?.title}</h2>
                    {/* <div className="detail-top">
                        <img src={require('../../assets/images/blog1.png')} />
                        <div>
                            <p>Oscar Wallis</p>
                            <span>Published</span>
                            <span>.</span>
                            <span>4 min read</span>
                            <span>.</span>
                            <span>2 days ago</span>
                        </div>
                    </div> */}
                    <img src={item?.image?.image} className="img-fluid" />
                    <div dangerouslySetInnerHTML={{ __html: item?.content }} className="article" />
                    <span className="topic">#{item?.category.title}</span>
                    {/* <span className="topic">#Medicine</span>
                    <span className="topic">#Humor</span>
                    <span className="topic">#Emergency Room</span>
                    <span className="topic">#Satire</span>
                    <span className="topic">#Comedy</span> */}
                    <h3 className="recommended">Recommended Topics</h3>
                    {relatedBlogs.length > 0 &&
                        relatedBlogs.map((blog) =>
                            <>
                                <div className="detail-list" key={blog.id}>
                                    <div className="list">
                                        <BlogItem item={blog} />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BlogDetail;